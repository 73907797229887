import { Container, Spacer, Stack, useDisclosure } from '@chakra-ui/react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Footer,
  Headings,
  HeroHeader,
  HomeButtons,
  InfoDrawer,
  SchedulesModal,
  TodayInfos,
} from 'components/main';
import { FC, useEffect, useState } from 'react';

import type { NextPage } from 'next';
import Head from 'next/head';

const EmptyDrawer: FC = () => <></>;

const Home: NextPage = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visible, setVisible] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState<FC>(EmptyDrawer);
  const [drawerTitle, setDrawerTitle] = useState('');
  const showCalendar = () => {
    trackEvent({ category: 'Page Action', action: 'show-calendar' });
    setVisible(true);
  };
  const closeHandler = () => {
    trackEvent({ category: 'Page Action', action: 'close schedules' });
    setVisible(false);
  };

  // Track page view
  useEffect(() => {
    trackPageView({ documentTitle: 'Home page' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessName = 'Pizza Mario';
  const subtitle = 'La camionette pizza de notre village';

  return (
    <Container maxW="5xl" p={0}>
      <Head>
        <title>Pizza Mario avec 123Miam 🤤</title>
        <meta name="description" content="menu pizza Mario" />
      </Head>

      <Stack>
        <HeroHeader
          setDrawerComponent={setDrawerComponent}
          setDrawerTitle={setDrawerTitle}
          openDrawer={() => {
            trackEvent({ category: 'Navigation', action: 'click-event' });
            onOpen();
          }}
        />

        <Container
          borderRadius={'3rem 3rem 0 0'}
          bgColor="white"
          top={'-2rem'}
          position="relative"
          minHeight={'30vh'}
          px={5}
          py={10}
        >
          <Headings businessName={businessName} subtitle={subtitle} />
          <TodayInfos businessName={businessName} showCalendar={showCalendar} />
          <HomeButtons />
        </Container>

        <Spacer />
        <Footer />
      </Stack>

      <InfoDrawer
        onClose={onClose}
        isOpen={isOpen}
        title={drawerTitle}
        Component={drawerComponent}
      />

      <SchedulesModal visible={visible} closeHandler={closeHandler} />
    </Container>
  );
};

export default Home;
